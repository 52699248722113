import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../../../components/ModalContainer';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

const AuditTable = ({ tableHeaders, data, noDataMessage, lessColumns, audit }: any) => {

    const navigate = useNavigate()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { t, i18n } = useTranslation()
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });


    return (
        <div className={`table_container ${lessColumns && isDesktop && "lessColumns"}  audit`}>
            <div className="table_header">
                {tableHeaders?.map((header: any, index: number) => (
                    <span className={`head ${header.customClass} `} key={index}>{header.label}</span>
                ))}
            </div>
            <div className={`table_data ${(!data || data?.length == 0) && "no_data"}`}>
                {data?.length > 0 ?
                    <>
                        {data?.map((item: any, index: number) => (
                                <div className="item clickable has_logo" key={index}>     
                                 <div className="column logo ">
                                    {/* <div>
                                        {item?.logo_url && <img src={item?.logo_url} alt="" />}
                                    </div> */}
                                       <div className='logo-container'>
                                        {item?.model?.product?.service_provider?.logo_url && <img src={item?.model?.product?.service_provider?.logo_url} alt="" />}
                                    </div>
                                    <div className='name-container'>
                                    {item?.model?.product?.service_provider?.name[i18n.language]|| "-"}
                                    </div>
                                </div>      
                                    {/* <div className="column text-center">{item?.vehicle_model.vehicle_make.name|| "-"}</div>              */}
                                    <div className="column text-center">{item?.created_by?.first_name + " " + (item?.created_by?.last_name || "") || "-"}</div>             
                                    <div className="column text-center small">{moment(item?.updated_at).format("DD-MM-YYYY") || "-"}</div>             
                                    <div className="column text-center small">{moment(item?.updated_at).format("HH:mm") || "-"}</div>             
                                    <div className="column text-center extra-width">
      { item?.changes && Object.keys(item?.changes).length > 0 && item?.action == "updated" ? 
        `${item?.action} ${Object.keys(item?.changes)[0]} from ${item?.changes[Object.keys(item?.changes)[0]]?.old_value == "1" ? "pending" :  item?.changes[Object.keys(item?.changes)[0]]?.old_value == "2" ? "in-progress" : item?.changes[Object.keys(item?.changes)[0]]?.old_value == "3" ? "accepted" : "rejected"} to ${item?.changes[Object.keys(item?.changes)[0]]?.new_value == "1" ? "pending" :  item?.changes[Object.keys(item?.changes)[0]]?.new_value == "2" ? "in-progress" : item?.changes[Object.keys(item?.changes)[0]]?.new_value == "3" ? "accepted" : "rejected"}` 
        : "Created" 
      || "-"}
    </div>   
                                </div>
                        ))}
                    </>
                    :
                    <h6>{noDataMessage}</h6>
                }
            </div>
        </div>
    );
}

export default AuditTable;