import { Formik, Form } from "formik";
import * as Yup from "yup";
import FieldWrapper from "../../../components/formInputs/FieldWrapper";
import SectionHeader from "../../../components/SectionHeader";
import Button from "../../../components/buttons/Button";
import { useContext, useEffect, useRef, useState } from "react";
import FormUpload from "../../../components/formInputs/FormUpload";
import { buildFormData, scrollToError } from "../../../utils/HelperFunctions";
import { generalCreate, generalGet } from "../../../API/api";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import FormSkeleton from "../../../components/loaders/FormSkeleton";
import { IService } from "../types/interfaces";
import { useTranslation } from "react-i18next";
import { authContext } from "../../../store/context/authContext";

const CreateServiceProviderForm = () => {

    const { id } = useParams()
    const formRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [serviceData, setServiceData] = useState<IService | null>()
    const [shownImage, setShownImage] = useState<string | null>()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { catchError } = useContext(authContext)

    const { data, isSuccess, isLoading } = useQuery({
        queryKey: ["service provider form", id],
        queryFn: () => generalGet(`/service-providers/${id}`),
        enabled: !!id,
    });

    useEffect(() => {
        const reqData = data?.data.data.serviceProvider
        if (isSuccess) {
            setServiceData(reqData)
            setShownImage(reqData?.logo_url)
        }

    }, [isSuccess, data, id])

    useEffect(() => {
        if (!id) {
            setServiceData(null)
            setShownImage(null)
        }
    }, [id])

    const handleSubmit = (values: any) => {
        let route = "/service-providers"
        if (id) route = `/service-providers/${id}`
        setLoading(true)
        const formData = new FormData()
        buildFormData(formData, values, "");
        generalCreate({ route, values: formData }).then(res => {
            toast.success(`${t("serviceProvider")} ${!id ? t("created") : t("updated")} ${t("successfully")}`)
            navigate("/services")
        }
        ).catch(error => {
            catchError(error, setLoading)
        })
    }

    const validationSchema =
        Yup.object({
            logo: Yup.mixed().required(t("required")),
            name: Yup.object().shape(
                {
                    ar: Yup.string().required(t("required")).matches(/^[\u0600-\u06FF\s]+$/, t("must_ar")).min(3, t("name_short")),
                    en: Yup.string().required(t("required")).matches(/^[A-Za-z\s]+$/, t("must_en")).min(3, t("name_short")),
                }
            ),
            address: Yup.object().shape(
                {
                    street: Yup.string().required(t("required")),
                    city: Yup.string().required(t("required")),
                }
            ),
            limit: Yup.number().typeError(t("must_no")).required(t("required")).min(0, t("must_great"))
        });

    const initialValues: IService = {
        name: { en: serviceData?.name?.en || "", ar: serviceData?.name?.ar || "" },
        address: serviceData?.address ? { street: serviceData?.address?.street || "", city: serviceData?.address.city || "" } : {
            street: "", city: ""
        },
        limit: +(serviceData?.limit as string) || "",
        logo: serviceData?.logo_url || "",
    }

    if (isLoading) return <FormSkeleton featuredSections={1} />

    return (

        <div className={'form_section'} ref={formRef}>
            <Formik
                validateOnMount
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    if (typeof values.logo == "string") delete values.logo;
                    let value = { ...values, _method: "POST" }
                    if (id) { value = { ...value, _method: "PUT" } }
                    handleSubmit({ ...value })
                }}
            >
                {(formik) => (
                    <>
                        <Form>
                            <SectionHeader title={t("ServiceProviderDetails")} customStyle="last">
                                <div className="inputs_group">
                                    <FieldWrapper title={t("logo")}>
                                        <FormUpload
                                            errors={formik?.errors.logo as string}
                                            touched={formik?.touched?.logo as boolean}
                                            setShownImage={setShownImage}
                                            shownImage={shownImage!}
                                            formik={formik}
                                            name={`logo`}
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper noPadding />
                                    <FieldWrapper noPadding />
                                    <FieldWrapper noPadding />
                                </div>
                                <div className="inputs_group">
                                    <FieldWrapper
                                        title={t("name_en")}
                                        inputName={`name[en]`}
                                        inputPlaceholder={"alrajhi bank"}
                                        inputError={formik.errors.name?.en as string}
                                        inputTouched={formik.touched.name?.en as boolean}
                                        input
                                        customPadding
                                    />
                                    <FieldWrapper
                                        title={t("name_ar")}
                                        inputName={`name[ar]`}
                                        inputPlaceholder={"مثال: بنك الراجحي"}
                                        inputError={formik.errors.name?.ar as string}
                                        inputTouched={formik.touched.name?.ar as boolean}
                                        input
                                        customPadding
                                        dir={"rtl"}
                                    />
                                </div>
                                <div className="inputs_group">
                                    <FieldWrapper
                                        title={t("city")}
                                        inputName={`address[city]`}
                                        inputPlaceholder={t("city_ex")}
                                        inputError={formik.errors.address?.city as string}
                                        inputTouched={formik.touched.address?.city as boolean}
                                        input
                                        customPadding
                                        border
                                    />
                                    <FieldWrapper
                                        title={t("street")}
                                        inputName={`address[street]`}
                                        inputPlaceholder={t("street_ex")}
                                        inputError={formik.errors.address?.street as string}
                                        inputTouched={formik.touched.address?.street as boolean}
                                        input
                                        customPadding
                                    />
                                </div>
                                <div className="inputs_group">
                                    <FieldWrapper
                                        title={t("limit")}
                                        inputName={`limit`}
                                        inputPlaceholder={"10000"}
                                        inputError={formik.errors.limit as string}
                                        inputTouched={formik.touched.limit as boolean}
                                        input
                                        customPadding
                                        border
                                        noPadding
                                        showUnit
                                        unit={t("sar")}
                                        type="number"
                                    />
                                    <FieldWrapper noPadding />
                                    <FieldWrapper noPadding />
                                </div>
                            </SectionHeader>
                            <div className="form_button reverse">
                                <Button loading={loading} onClick={() => { scrollToError(!formik.isValid, formRef) }}>
                                    <span className="bold">{t("save")}</span>
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </div >

    );
}

export default CreateServiceProviderForm;