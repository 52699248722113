import { useTranslation } from "react-i18next";
import moment from "moment";
import { dragIcon, OfferIcon } from "../../../../config/variables";
import { ISearchProduct } from "../../types/interface";
import { useDrag, useDrop } from "react-dnd";
import { useCallback } from "react";

const DraggableProductCard = ({ item, index, moveCard }: { moveCard: any, item: ISearchProduct, index: number }) => {

    const { t, i18n } = useTranslation()
    const [, ref] = useDrag({
        type: 'CARD',
        item: { index },
    });
    const [, drop] = useDrop({
        accept: 'CARD',
        hover(item: any) {
            if (item.index !== index) {
                moveCard(item.index, index);
                item.index = index;
            }
        },
    });

    const hasOffer = useCallback(() => {
        const hasAdminFee = item?.admin_fee?.old_admin_fee_value &&
            item?.admin_fee?.old_admin_fee_value != item?.admin_fee?.admin_fee_value
        const hasProfit = item?.old_profit_value && item?.old_profit_value != item?.profit_value
        return {
            hasAdminFee,
            hasProfit,
            hasOffer: hasAdminFee || hasProfit
        }
    }, [item])

    return (
        <div ref={node => ref(drop(node))} className="product-card drag">
            <div className="drag-icon">
                {dragIcon}
                <span>{index + 1}</span>
            </div>
            {hasOffer().hasOffer && <div className="icon">
                {OfferIcon}
            </div>}
            <section className="service-data">
                <div className='logo-container'>
                    {item?.service_provider?.logo_url && <img src={item?.service_provider?.logo_url} alt="" />}
                </div>
                <span>{item?.service_provider?.name[i18n.language]}</span>
            </section>
            <section className="price-data">
                <div className="data">
                    <div>
                        <p>{t("total_amount")}</p>
                        <span>{item?.total_price?.toLocaleString('en-US', { minimumFractionDigits: 2 })} {t("sar")}</span>
                    </div>
                    <div>
                        <p>{t("monthly_install")}</p>
                        <span>{item?.monthly_installments?.toLocaleString('en-US', { minimumFractionDigits: 2 })} {t("sar")}</span>
                    </div>
                    <div>
                        <p>{t("financialDuration")}</p>
                        <span>{item?.duration_to} {t("years")}</span>
                    </div>
                </div>
                {hasOffer().hasOffer && item.offer_end_date && <div className="offer">
                    <span>{t("offerEnds")} {moment(item.offer_end_date).format("MMM Do YYYY")}</span>
                </div>}
            </section>

        </div>
    );
}

export default DraggableProductCard;