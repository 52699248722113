import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { authContext } from "../../../store/context/authContext";
import Button from "../../../components/buttons/Button";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import { customStyles, sortStyles } from "../../../utils/SelectStyles";
import axios from "axios";
import { generalPut } from "../../../API/api";
import { useNavigate, useParams } from "react-router-dom";



export default function ApplicationStatusModal({ currentStatus, pId, setModal, setRefetchData, successMsg, warningMsg, setIsActive, post }: any) {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation()
    const { catchError } = useContext(authContext)
    const [selectedStatus, setSelectedStatus] = useState<any>(null)
    const navigate = useNavigate()
    const { id } = useParams()
    const statusOptions = [
        // {  label: "Pending", value: "1"},
        { label: "In Progress", value: "2" },
        { label: "Accepted", value: "3" },
        { label: "Rejected", value: "4" },
        // {  label: "Rejected With Comments", value: "5"},
    ]


    const handleStatusChange = () => {

        setLoading(true);
        generalPut(`/applications/${pId}/change-status`, { status: selectedStatus.value })
            .then(res => {
                // handle success response

                toast.success(successMsg);
                setRefetchData(new Date())
                setLoading(false);
                setModal(-1)
                navigate(`/applications/${id}?updated=${new Date()}`)



            })
            .catch(error => {
                // handle error response
                setLoading(false);
            });
    }
    useEffect(() => {
        if (currentStatus) {
            setSelectedStatus(statusOptions.find((status: any) => status.value == currentStatus))
        }

    }, [currentStatus])


    return (
        <div className="common-modal delete appStatus">
            <h4>{warningMsg}</h4>
            <div className="selectStatusWrapper">
                <Select
                    value={selectedStatus}
                    placeholder={t("selectStatus")}
                    isSearchable={false}
                    isClearable={false}
                    isMulti={false}
                    options={statusOptions}
                    className={`select-drop-down `}
                    noOptionsMessage={() => t("no_options")}
                    classNamePrefix="react-select"
                    styles={customStyles as any}
                    onChange={(e: any) => setSelectedStatus(e)}
                />
            </div>
            <div className="buttons">
                <Button
                    noScrollUp={!selectedStatus ? true : false}
                    loading={loading}
                    onClick={() => selectedStatus && handleStatusChange()}
                    disabled={!selectedStatus}
                >
                    <span className="bold">{t("confirm")}</span>
                </Button>
                <Button type={"submit"} onClick={() => setModal(-1)}>
                    {t("cancel")}
                </Button>
            </div>
        </div>
    );
}
