import { IWorkSector } from "../../modules/products/types/interfaces"

interface IProps {
  item: {
    label: string
    value: string
  }
  value: boolean
  formik?: any
  checked: string[],
  name: string,
  workSectors?: IWorkSector[]
}

const Checkbox = ({ item, value, formik, checked, name }: IProps) => {
  const handleChange = () => {
    // Individual checkbox logic
    if (checked && checked.some((i) => i === item.value)) {
      formik?.setFieldValue(name, checked?.filter((i) => i !== item.value));
    } else {
      formik?.setFieldValue(name, [...checked, item.value]);

    }
  };

  return (
    <label>
      <input
        id={`${name}`}
        type="checkbox"
        checked={value}
        onChange={handleChange}
      />
      {item.label}
    </label>
  );
};

export default Checkbox