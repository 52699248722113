import { useTranslation } from "react-i18next";
import FieldWrapper from "../../../../components/formInputs/FieldWrapper";
import SectionHeader from "../../../../components/SectionHeader";
import { FormikProps } from "formik";
import RadioInput from "../../../../components/formInputs/RadioInput";
import { days, Gender, months, years } from "../../../../config/FixedData";
import { customStyles } from "../../../../utils/SelectStyles";

interface IProps {
    formik: FormikProps<any>;
}
const PersonalInfoForm = ({ formik }: IProps) => {

    const { t } = useTranslation()

    return (
        <SectionHeader title={t("personal_info")} >
            <div className="inputs_group">
                <FieldWrapper
                    title={t("fullNameEn")}
                    inputName={`full_name[en]`}
                    inputError={(formik.errors.full_name as { en: string })?.en}
                    inputTouched={(formik.touched.full_name as { en: boolean })?.en}
                    inputPlaceholder={`${t("ex")}: Ali`}
                    input
                />
                <FieldWrapper
                    title={t("fullNameAr")}
                    inputName={`full_name[ar]`}
                    inputError={(formik.errors.full_name as { ar: string })?.ar}
                    inputTouched={(formik.touched.full_name as { ar: boolean })?.ar}
                    inputPlaceholder={`مثال: علي`}
                    input
                    dir="rtl"
                />
            </div>
            <div className="inputs_group">
                <FieldWrapper
                    title={t("phone")}
                    inputName={`mobile`}
                    inputError={formik.errors.mobile as string}
                    inputTouched={formik.touched.mobile as boolean}
                    inputPlaceholder={`${t("ex")}: 0511111111`}
                    input
                    // type="number"
                />
                <FieldWrapper
                    title={t("zipCode")}
                    inputName={`zip_code`}
                    inputError={formik.errors.zip_code as string}
                    inputTouched={formik.touched.zip_code as boolean}
                    inputPlaceholder={`${t("ex")}: 123`}
                    input
                    type="number"
                />
            </div>
            <div className="inputs_group">
                <FieldWrapper
                    title={t("email")}
                    inputName={`email`}
                    inputError={formik.errors.email as string}
                    inputTouched={formik.touched.email as boolean}
                    inputPlaceholder={`${t("ex")}: example@example.com`}
                    input
                />
            </div>
            <div className="inputs_group">
                <FieldWrapper
                    title={t("nationalId")}
                    inputName={`national_id`}
                    inputError={formik.errors.national_id as string}
                    inputTouched={formik.touched.national_id as boolean}
                    inputPlaceholder={`${t("ex")}: 10111111111`}
                    input
                />
            </div>
            <FieldWrapper noPadding title={t("dob")}>
                <div className="inputs_group">
                    <FieldWrapper
                        customPadding
                        title={t("day")}
                        inputName={`day_of_birth`}
                        inputPlaceholder={t("day")}
                        inputError={formik.errors.day_of_birth as string}
                        inputTouched={formik.touched.day_of_birth as boolean}
                        options={[
                            ...days.map((day) => ({ label: day.toString(), value: day.toString() })),
                        ]}
                        onChange={(e: any) => formik.setFieldValue(`day_of_birth`, e?.value)}
                        selectStyle={customStyles}
                        select
                    />

                    <FieldWrapper
                        customPadding
                        title={t("month")}
                        inputName={`month_of_birth`}
                        inputPlaceholder={t("Month")}
                        inputError={formik.errors.month_of_birth as string}
                        inputTouched={formik.touched.month_of_birth as boolean}
                        options={[
                            ...months.map((month, index) => ({ label: month.toString(), value: (index + 1).toString() })),
                        ]}
                        onChange={(e: any) => formik.setFieldValue(`month_of_birth`, e?.value)}
                        selectStyle={customStyles}
                        select
                    />

                    <FieldWrapper
                        customPadding
                        title={t("year")}
                        inputName={`year_of_birth`}
                        inputPlaceholder={t("Year")}
                        inputError={formik.errors.year_of_birth as string}
                        inputTouched={formik.touched.year_of_birth as boolean}
                        options={[
                            ...years.sort((a, b) => b - a).map((year) => ({ label: year.toString(), value: year.toString() })),
                        ]}
                        onChange={(e: any) => formik.setFieldValue(`year_of_birth`, e?.value)}
                        selectStyle={customStyles}
                        select
                    />


                </div>
            </FieldWrapper>
            <div className="inputs_group">
                <FieldWrapper title={t("gender")}>
                    <RadioInput
                        name={`gender`}
                        onChange={(e: any) => {
                            formik.setFieldValue(`gender`, e.target.value)
                        }}
                        value={formik?.values?.gender}
                        key={formik?.values?.gender}
                        data={Gender}
                    />
                </FieldWrapper>
                <FieldWrapper noPadding />
            </div>
            <div className="inputs_group">
                <FieldWrapper
                    title={t("jobTitle")}
                    inputName={`job_title`}
                    inputError={formik.errors.job_title as string}
                    inputTouched={formik.touched.job_title as boolean}
                    inputPlaceholder={`${t("ex")}: ${t("engineer")}`}
                    input
                />
                <FieldWrapper noPadding />
            </div>
            <FieldWrapper
                title={t("address")}
                noPadding
            >
                <div className="inputs_group">
                    <FieldWrapper
                        title={t("city")}
                        inputName={`address[city]`}
                        inputError={(formik?.errors?.address as { city: string })?.city}
                        inputTouched={(formik?.touched?.address as { city: boolean })?.city}
                        inputPlaceholder={`${t("ex")}: ${t("jeddah")}`}
                        input
                    />
                    <FieldWrapper
                        title={t("street")}
                        inputName={`address[street]`}
                        inputError={(formik?.errors?.address as { street: string })?.street}
                        inputTouched={(formik?.touched?.address as { street: boolean })?.street}
                        inputPlaceholder={`${t("ex")}: ${t("alaml")}`}
                        input
                    />
                </div>
                <div className="inputs_group">
                    <FieldWrapper
                        title={t("apartmentNo")}
                        inputName={`address[apartment_no]`}
                        inputError={(formik?.errors?.address as { apartment_no: string })?.apartment_no}
                        inputTouched={(formik?.touched?.address as { apartment_no: boolean })?.apartment_no}
                        inputPlaceholder={`${t("ex")}: 6`}
                        input
                        type="number"
                    />
                    <FieldWrapper noPadding />
                </div>
            </FieldWrapper>
        </SectionHeader>
    );
}

export default PersonalInfoForm;