import { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { generalGet } from "../../../API/api";
import { useNavigate, useParams } from "react-router-dom";
import BasicInfoSkeleton from "../../../components/loaders/BasicInfoSkeleton";
import TableSkeleton from "../../../components/loaders/TableSkeleton";
import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useTranslation } from "react-i18next";
import ListHeader from "../../../components/ListHeader";
import Button from "../../../components/buttons/Button";
import ModalContainer from "../../../components/ModalContainer";
import Cookies from "js-cookie";
import { authContext } from "../../../store/context/authContext";
import DistributorInfo from "../../../modules/distributors/components/DistributorInfo";
import { IDistributor, IDistributorContact } from "../../../modules/distributors/types/interfaces";
import CreateContactForm from "../../../components/CreateContactForm";
import ContactsTableContainer from "../../../components/ContactsTableContainer";
import ApplicationsTableContainer from "../../../modules/applications/components/ApplicationsTableContainer";
import { IApplication } from "../../../modules/applications/types/interfaces";

const DistributorDetails = () => {

    const [openModel, setOpenModel] = useState<boolean | number>(false);
    const { catchError } = useContext(authContext);
    const [contactId, setContactId] = useState<string | null>(null)
    const [contactData, setContactData] = useState<IDistributorContact | null>(null)
    const { t, i18n } = useTranslation()
    const { id } = useParams()
    const dispatch = useDispatch()
    const [distributorData, setDistributorData] = useState<IDistributor>()
    const [applicationsData, setApplicationsData] = useState<IApplication>()
    const [refetch, setRefetch] = useState<any>()
    const navigate = useNavigate()

    useEffect(() => {
        id && Cookies.set("distributor_ID", id)
        return () => Cookies.remove("distributor_ID")
    }, [id])


    const { data, isSuccess, isLoading, error } = useQuery({
        queryKey: ["Distributor Details", id, i18n.language, refetch],
        queryFn: () => generalGet(`/distributors/${id}`),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        setContactData(distributorData?.contacts?.find((contact: IDistributorContact) => contact.id === contactId)!)
    }, [contactId, distributorData])

    useEffect(() => {
        const reqData = data?.data?.data?.distributor
        const appData = data?.data?.data?.applicationRequests

        if (isSuccess) {
            setDistributorData(reqData)
            setApplicationsData(appData.sort((a:any, b:any) => Number(b?.id) - Number(a?.id)))

            dispatch(setBreadCrumbsData({
                links: [{ label: t("links.distributors"), path: "/distributors" }
                    , { label: reqData.name, path: `/distributors/${id}` }
                ],
                page_title: reqData.name[i18n.language],
                distributorId: id
            }))
        }
    }, [isSuccess, data, i18n, id])

    useEffect(() => {
        if (error) {
            catchError(error)
        }

    }, [error])

    const contactTableHeaders = [
        { label: t("name") },
        { label: t("email") },
        { label: t("phone") },
        { label: t("actions"), customClass: "actions_col" },
    ];

    const applicationsTableHeaders = [
        { label: t("appNo"), },
        { label: t("make&model"), },
        { label: t("serviceProvider"), },
        { label: t("Distributor"), },
        { label: t("step"), },
        { label: t("status"), },
        { label: t("createdBy"), },
        { label: t("lastUpdated"), },
    ];

    if (isLoading) return <div>
        <BasicInfoSkeleton />
        <TableSkeleton columns={5} />
    </div>

    return (
        <div className="page_content service_details_page">
            <DistributorInfo
                id={{
                    route: `/distributors/create-distributor/${id}`, text: t("editDist")

                }}
                title={t("distributor_info")} data={[
                    { key: t("name"), value: data.data.data.distributor.name[i18n.language] || "-" },
                    { key: t("address"), value: distributorData?.address?.city + ", " + distributorData?.address?.street },
                    { key: t("phone"), value: distributorData?.phone! },
                ]} />
            <div className="table-wrapper">
                <ListHeader customClass="row" >
                    <h2>{t("applications")}</h2>
                </ListHeader>
                <ApplicationsTableContainer
                    tableHeaders={applicationsTableHeaders}
                    data={applicationsData}
                    noDataMessage={t("No_applications_found")}
                    setRefetchData={setRefetch}
                />
            </div>
            <div className="table-wrapper">
                <ListHeader customClass="row">
                    <h2>{t("contacts")}</h2>
                    <Button text={t("create_contacts")} onClick={() => setOpenModel(1)} />
                </ListHeader>
                <ContactsTableContainer
                    tableHeaders={contactTableHeaders}
                    data={distributorData?.contacts}
                    noDataMessage={t("No_contacts_found")}
                    border
                    setOpenModel={setOpenModel}
                    setContactId={setContactId}
                    contactId={contactId}
                    setRefetchData={setRefetch}
                    deleteRoute={"/distributors/distributor-contact"}
                />
            </div>
            {openModel == 1 &&
                <ModalContainer small>
                    <CreateContactForm
                        setContactId={setContactId}
                        contactData={contactData}
                        contactId={contactId}
                        setContactData={setContactData}
                        setRefetch={setRefetch}
                        setOpen={setOpenModel}
                        parentId={id}
                        routeName={"/distributors"}
                    />
                </ModalContainer>
            }
        </div>
    );
}
export default DistributorDetails