import { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { generalGet } from "../../../API/api";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useTranslation } from "react-i18next";
import { IWorkSector } from "../../../modules/products/types/interfaces";
import CreateProductForm from "../../../modules/products/components/productForm/CreateProductForm";
import FormSkeleton from "../../../components/loaders/FormSkeleton";
import { authContext } from "../../../store/context/authContext";

const CreateProduct = () => {
    const { t, i18n } = useTranslation()
    const { catchError } = useContext(authContext)
    const { id } = useParams()
    const dispatch = useDispatch()
    const [productData, setProductData] = useState<any | null>(null)
    const [workSectors, setWorkSectors] = useState<any>(null)
    const { data, isSuccess, isLoading, error } = useQuery({
        queryKey: [id],
        queryFn: () => id && generalGet(`/products/${id}`),
        refetchOnWindowFocus: false,
        gcTime: 0,
        enabled: !!id
    });

    const { data: workSectorsData, isSuccess: workSectorsSuccess, isLoading: workSectorsLoading } = useQuery({
        queryKey: ["work sectors", i18n.language],
        queryFn: () => generalGet(`/work-sectors`),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        const reqData = workSectorsData?.data.data
        workSectorsSuccess && setWorkSectors([...reqData?.map((sector: IWorkSector) => {
            return { label: sector.name, value: sector.id.toString() }
        })])
    }, [workSectorsSuccess, workSectorsData])


    useEffect(() => {

        if (isSuccess && id && data) {
            const reqData = data?.data?.data
            setProductData(() => {
                reqData?.conditions?.map((item: any) => {
                    item.work_sectors = item.work_sectors.map((sector: IWorkSector) => {
                        return sector.id
                    })
                    return item
                })
                return reqData
            }
            )
            dispatch(setBreadCrumbsData({
                links: [{ label: t("links.products"), path: "/products" }
                    , { label: reqData?.service_provider?.name[i18n.language], path: `/products/${id}` }
                ],
                page_title: t("links.create_product"),
            }))

        } else if (!id) {
            dispatch(setBreadCrumbsData({
                links: [{ label: t("links.products"), path: "/products" }
                    , { label: t("links.create_product"), path: `/products/create-product` }
                ],
                page_title: t("create_product"),
            }))
        }
    }, [isSuccess, data, id, t, i18n])

    //Reset data when navigating from edit product to create product
    useEffect(() => {
        if (!id) {
            setProductData(null)
        }
    }, [id])

    useEffect(() => {
        error && catchError(error)
    }, [error])

    if (isLoading) return <div>
        <FormSkeleton featuredSections={1} noImage />
    </div>
    return (
        <div className="page_content product_details_page">
            <CreateProductForm productData={productData} workSectors={workSectors} id={id} />
        </div>
    );
}
export default CreateProduct;