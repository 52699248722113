import { useContext, useEffect, useMemo, useState } from "react";
import ModalContainer from "../../../components/ModalContainer";
import Button from "../../../components/buttons/Button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useNavigate, useParams } from "react-router-dom";
import { generalGet } from "../../../API/api";
import { useQuery } from "@tanstack/react-query";
import { authContext } from "../../../store/context/authContext";
import InitialLoader from "../../../components/loaders/InitialLoader";
import { approvedStepIcon, pendingStepIcon, rejectedStepIcon } from "../../../config/variables";
import DistributorInfo from "../../../modules/distributors/components/DistributorInfo";
import moment from "moment";
import Cookies from "js-cookie";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandAccordion from "../../../modules/products/components/productForm/ExpandAccordion";
import ListHeader from "../../../components/ListHeader";
import CommonHighlights from "../../../modules/serviceProvider/components/CommonHighlights";
import { IServiceInfo } from "../../../modules/serviceProvider/types/interfaces";
import ApplicationStatusModal from "../../../modules/applications/components/ApplicationStatusModal";
import AuditTable from "../../../modules/applications/components/AuditTable";


const ApplicationDetails = () => {

    const { catchError } = useContext(authContext)
    const [openModel, setOpenModel] = useState<boolean | number>(true);
    const { t, i18n } = useTranslation();
    const { id } = useParams()
    const navigate = useNavigate();
    const [application, setApplication] = useState<any>()
    const [status, setStatus] = useState<{ value: number, lang: string }>()
    const [expanded, setExpanded] = useState(0)
    const [refetch, setRefetch] = useState<boolean>(false)
    const [openStatusModal, setOpenStatusModal] = useState<number>(-1)
    const [auditTrailData, setAuditTrailData] = useState<any>([])


    useEffect(() => {
        id && Cookies.set("application_ID", id)
        return () => Cookies.remove("application_ID")
    }, [id, i18n])

    const dispatch = useDispatch()
    dispatch(setBreadCrumbsData({
        links: [{ label: t("links.applications"), path: `/applications/${id}` }],
        page_title: t("application") + ` #${id}`,
        applicationId: id
    }))

    const { data, isSuccess, isLoading, error } = useQuery({
        queryKey: ["Application Details", id, i18n, refetch],
        queryFn: () => generalGet(`/applications/${id}`),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        error && catchError(error)
    }, [error])

    useEffect(() => {
        const reqData = data?.data?.data        
        isSuccess &&
            setApplication(reqData)
        setStatus(reqData?.status)

        const audits = reqData?.application_products?.flatMap((product: any) => product.audits)

        setAuditTrailData(audits.sort((a: any, b: any) => Number(b?.id) - Number(a?.id)))

    }, [isSuccess, data, i18n])

   
    const fixedData = useMemo(() => {
        return [
            {
                title: t("vehicle") ,
                number: application?.vehicle?.variant_id?.vehicle_model?.vehicle_make +", " + application?.vehicle?.variant_id?.vehicle_model?.name || "-" ,
            },
            {
                title: t("serviceProvider") ,
                number: application?.service_provider[i18n.language] || "-" ,
            },
            {
                title: t("Distributor") ,
                number: application?.distributor || "-" ,
            },
            {
                title: t("price") ,
                number: application?.application_products[0]?.vehicle_price.toLocaleString('en-US', { minimumFractionDigits: 2 }) + " " + t("sar") || "-" ,
            },
            {
                title: t("sales") ,
                number: application?.user?.first_name || "-" || "-" ,
            },
            {
                title: t("lastUpdated") ,
                number: moment(application?.audits[0]?.updated_at).format("DD-MM-YYYY") || "-",
            },
          

        ]
    }, [application, t])

    
    const auditTableHeaders = [
        { label: t("serviceProvider"), customClass: "logo" },
        { label: t("user") , customClass: "text-center"},
        { label: t("date"), customClass: "text-center small" },
        { label: t("time"), customClass: "text-center small" },
        { label: t("action"), customClass: "text-center extra-width" },
    ]

    


    if (isLoading) return <div className="centered-flex" style={{ minHeight: "70vh" }}><InitialLoader pageLoader /></div>


    return (
        <div className="page_content service_details_page">
        
            <ListHeader customClass="row noMargin">
                <h2>{t("generalInfo")}</h2>

            </ListHeader>
            <CommonHighlights data={fixedData as IServiceInfo[]} />
            
            <div className="field-array-container noPadding noMarginBottom">
                 <Accordion
                    expanded={expanded == 0}
                    onChange={(e) => { e.stopPropagation(); setExpanded(expanded == 0 ? -1 : 0) }}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={`header-container ${expanded != 0 && 'expand'}`}
                    >
                        <p><ExpandAccordion expand={expanded == 0} /> {t("vehicleInfo")}</p>
                      

                    </AccordionSummary>
                    <AccordionDetails>
                    <DistributorInfo noBg grid extraTop data={[
                        { key: t("make"), value: application?.vehicle?.variant_id?.vehicle_model?.vehicle_make || "-" },
                        { key: t("model"), value: application?.vehicle?.variant_id?.vehicle_model?.name || "-" },
                        { key: t("variant"), value: application?.vehicle?.variant_id?.display_name[i18n.language] || "-" },
                        { key: t("exterior_color"), value: application?.vehicle?.color_wheel_base_id?.display_name || "-" },
                        { key: t("interior_color"), value: application?.vehicle?.trim_cab_type_id?.display_name || "-" },
                        { key: t("chassisNo"), value: application?.vehicle?.chassis || "-" },
                        { key: t("price"), value: application?.application_products[0]?.vehicle_price.toLocaleString('en-US', { minimumFractionDigits: 2 }) + " " + t("sar") || "-" },
                ]} />
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="field-array-container noPadding noMarginBottom">
                 <Accordion
                    expanded={expanded == 1}
                    onChange={(e) => { e.stopPropagation(); setExpanded(expanded == 1 ? -1 : 1) }}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={`header-container ${expanded != 1 && 'expand'}`}
                    >
                        <p><ExpandAccordion expand={expanded == 1} /> {t("customer_details")}</p>
                      

                    </AccordionSummary>
                    <AccordionDetails>
                    <DistributorInfo grid extraTop noBg data={[
                        { key: t("fullName"), value: application?.customer?.full_name || "-" },
                        { key: t("phone"), value: application?.customer?.mobile || "-" },
                        { key: t("email"), value: application?.customer?.email || "-" },
                        { key: t("nationality"), value: application?.customer?.nationality || "-" },
                        { key: t("nationalId"), value: application?.customer?.national_id || "-" },
                        { key: t("dob"), value: moment(application?.customer?.dob).format("DD-MM-YYYY") || "-" },
                        { key: t("gender"), value: application?.customer?.gender|| "-" },
                        { key: t("address"), value: application?.customer?.address?.apartment_no + " " + application?.customer?.address?.street + ", " + application?.customer?.address?.city || "-" },
                        { key: t("zipCode"), value: application?.customer?.zip_code|| "-" },
                        { key: t("jobTitle"), value: application?.customer?.job_title|| "-" },
                        { key: t("salaryTransfer"), value: application?.customer?.salary_transferred_to|| "-" },
                        
                    ]} />
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="field-array-container noMarginBottom">
                 <Accordion
                    expanded={expanded == 2}
                    onChange={(e) => { e.stopPropagation(); setExpanded(expanded == 2 ? -1 : 2) }}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={`header-container ${expanded != 2 && 'expand'}`}
                    >
                        <p><ExpandAccordion expand={expanded == 2} /> {t("financialDetails")}</p>
                      

                    </AccordionSummary>
                    <AccordionDetails>
                        {application?.application_products?.map((item: any, index: number) => 
                            
                        <DistributorInfo key={i18n.language} handleStatus={setOpenStatusModal} appId={item?.id} status={item?.status} grid financialCard data={[
                            {  hasLogo: item?.product?.service_provider?.logo_url , value: item?.product?.service_provider?.name[i18n.language] || "-" },
                            { key: t("financialDuration"), value: item?.product?.duration_to + " " + (item?.product?.duration_to == 1 ? t("year") : t("years")) || "-" },
                            { key: t("monthly_install"), value: item?.product?.total_price?.monthly_installments[item?.product?.duration_to - 1].toLocaleString('en-US', { minimumFractionDigits: 2 }) + " " +t("sar") || "-" },
                            { key: t("down_payment"), value: item?.product?.down_payment.toLocaleString('en-US', { minimumFractionDigits: 2 }) + " " +t("sar") || "-" },
                            { key: t("balloonPayment") , value: item?.product?.balloon_payment.toLocaleString('en-US', { minimumFractionDigits: 2 }) + " " +t("sar") || "-" },
                            { key: `${t("profitRate")} ${item?.product?.yearly_profit_rates[item?.product?.duration_to - 1]?.profit_percentage + " " +"%" || ""}`, value: item?.product?.yearly_profit_rates[item?.product?.duration_to - 1]?.profit_value.toLocaleString('en-US', { minimumFractionDigits: 2 }) + " " +t("sar") || "-" },
                            { key: `${t("insuranceRate")} ${item?.product?.insurance_fee?.insurance_fee_percentage + " " + "%" || ""}`, value: item?.product?.insurance_fee?.insurance_fee_value.toLocaleString('en-US', { minimumFractionDigits: 2 }) + " " + t("sar") || "-" },
                            { key: `${t("adminFees")} ${item?.product?.admin_fee?.admin_fee_percentage + " " + "%" || ""}`, value: item?.product?.admin_fee?.admin_fee_value.toLocaleString('en-US', { minimumFractionDigits: 2 }) + " " + t("sar") || "-" },
                            { key: t("total_amount") , value: item?.product?.total_price[item?.product?.duration_to - 1].toLocaleString('en-US', { minimumFractionDigits: 2 }) + " "  +t("sar") || "-" },
                            
                        ]} />   
                        )}
                  
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="table-wrapper">
                <ListHeader customClass="row">
                    <h2>{t("auditTrail")}</h2>
                </ListHeader>
                <AuditTable
                    tableHeaders={auditTableHeaders}
                    data={auditTrailData}
                    noDataMessage={t("noDataFound")}
                    // lessColumns
                    Audit
                />
            </div>

            {/* {openModel &&
                <ModalContainer small>
                    <div className="common-modal status">
                        <h4>{`${t("application")} #${id} ${status?.lang}`}</h4>
                        <div className="statusIcon">

                            {status?.value === 1 && pendingStepIcon}
                            {status?.value === 2 && approvedStepIcon}
                            {status?.value === 3 && rejectedStepIcon}

                        </div>
                        <p>{application?.current_step?.message} </p>
                        <div className="buttons">
                            <Button
                                onClick={() => {
                                    setOpenModel(false)
                                    window.scroll(0, 0);
                                    // navigate(`/applications`)
                                }
                                }
                            >
                                <span className="bold">{t("close")}</span>
                            </Button>

                        </div>
                    </div>
                </ModalContainer>
            } */}

            {openStatusModal != -1 && application && (
                <ModalContainer small overflow>
                    <ApplicationStatusModal
                        setRefetchData={setRefetch}
                        setModal={setOpenStatusModal}
                        successMsg={t("statusChanged")}
                        warningMsg={t("changeAppStatus")}
                        pId={openStatusModal}
                        currentStatus={application?.application_products.find((item: any) => item?.product?.status != 1)}
                       
                    />
                </ModalContainer>
            )}
        </div>
    );
}

export default ApplicationDetails;