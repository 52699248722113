import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useContext, useEffect, useState, useTransition } from "react";
import { useQuery } from "@tanstack/react-query";
import { generalGet } from "../../../API/api";
import TableSkeleton from "../../../components/loaders/TableSkeleton";
import ListHeader from "../../../components/ListHeader";
import SearchInputField from "../../../components/SearchInputField";
import { handleSorting, searchFilterLogic } from "../../../utils/HelperFunctions";
import { useTranslation } from "react-i18next";
import { authContext } from "../../../store/context/authContext";
import ApplicationsTableContainer from "../../../modules/applications/components/ApplicationsTableContainer";
import { IApplication } from "../../../modules/applications/types/interfaces";
import { sortStyles } from "../../../utils/SelectStyles";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import { TOptions } from "i18next";


const Applications = () => {
    const { catchError } = useContext(authContext)
    const { t, i18n } = useTranslation()
    const [selectedSPOption, setSelectedSPOption] = useState({label: 'All Service Providers', value: ''});
    const [selectedDistributorOption, setSelectedDistributorOption] = useState({label: 'All Distributors', value: ''});
    const [filteredList, setFilteredList] = useState<IApplication[]>([])
    const [searchInput, setSearchInput] = useState("")
    const [isPending, startTransition] = useTransition()
    const [refetch, setRefetch] = useState(false)
    const [applications, setApplications] = useState<IApplication[]>()
    const [shownList, setShownList] = useState<IApplication[]>()
    const [distributorsFilterOptions, setDistributorsFilterOptions] = useState<OptionsOrGroups<TOptions, GroupBase<TOptions>>>([])
    const [SPFilterOptions, setSPFilterOptions] = useState<OptionsOrGroups<TOptions, GroupBase<TOptions>>>([])

    const dispatch = useDispatch()

    dispatch(setBreadCrumbsData({
        links: [{ label: t("applications"), path: "/applications" }],
        page_title: t("applications"),
    }))


    const { data, isSuccess, isLoading, error } = useQuery({
        queryKey: ["applications", refetch, i18n],
        queryFn: () => generalGet("/applications?limit=100"),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        error && catchError(error)
    }, [error])

    useEffect(() => {
        const reqData = data?.data?.data
        isSuccess &&
        setApplications(reqData.sort((a: IApplication, b: IApplication) => Number(b?.id) - Number(a?.id)))
    }, [isSuccess, data])


    const tableHeaders = [
        { label: t("appNo"),  },
        { label: t("make&model"),  },
        { label: t("serviceProvider"),  },
        { label: t("Distributor"),  },
        { label: t("step"),  },
        { label: t("status"),  },
        { label: t("createdBy"),  },
        { label: t("lastUpdated"),  },
    ];

    useEffect(() => {
        
        setShownList(filteredList)
        if (searchInput) {
            const results = searchFilterLogic({ searchInput: searchInput, listOfData: filteredList, keys: ["id"] });
            startTransition(() => {
                setShownList(results)
            })
        }
        
    }, [applications, searchInput])
    


    useEffect(() => {
        const uniqueDistributors = [
            { label: 'All Distributors', value: "" },
            ...Array.from(new Set(applications?.map((app) => app?.distributor))).map((distributor) => ({ label: distributor, value: distributor })),
        ];
        const uniqueSP = [
            { label: 'All Service Providers', value: "" },
            ...Array.from(new Set(applications?.map((app) => app?.service_provider?.[i18n.language as keyof typeof app.service_provider]))).map((sp) => ({ label: sp, value: sp })),
          ];
        setDistributorsFilterOptions(uniqueDistributors)
        setSPFilterOptions(uniqueSP)
    }, [applications])


    const filterSP = (e: any) => {
        setSelectedSPOption(e)
        setSearchInput("")
    }
    const filterDistributor = (e: any) => {
        setSelectedDistributorOption(e)
        setSearchInput("")

    }

    useEffect(() => {
        if (!applications) return;
      
        let filteredApps = [...applications];
      
        if (selectedSPOption?.value) {
          filteredApps = filteredApps.filter(
            (app) => app?.service_provider?.[i18n.language as keyof typeof app.service_provider] === selectedSPOption.value
          );
        }
      
        if (selectedDistributorOption?.value) {
          filteredApps = filteredApps.filter(
            (app) => app?.distributor === selectedDistributorOption.value
          );
        }
      
        setFilteredList(filteredApps);
      }, [selectedSPOption, selectedDistributorOption, applications]);
      

      useEffect(() => {
        setShownList(filteredList)}, [filteredList])


    if (isLoading) return <TableSkeleton columns={10} withoutButton />

    return (
        <div className="services-page-container">
            <ListHeader>
                <div className="filter_container">
                    <SearchInputField placeholder={t("search_by_App_No")} setSearchInput={setSearchInput} searchInput={searchInput} />
                    <Select
                        value={selectedSPOption}
                        placeholder={t("serviceProvider")}
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                        options={SPFilterOptions}
                        className={`select-drop-down `}
                        noOptionsMessage={() => t("no_options")}
                        classNamePrefix="react-select"
                        styles={sortStyles as any}
                        onChange={filterSP}
                    />
                    <Select
                        value={selectedDistributorOption}
                        placeholder={t("Distributor")}
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                        options={distributorsFilterOptions}
                        className={`select-drop-down `}
                        noOptionsMessage={() => t("no_options")}
                        classNamePrefix="react-select"
                        styles={sortStyles as any}
                        onChange={filterDistributor}
                    />
                </div>
            </ListHeader>
            <ApplicationsTableContainer
                tableHeaders={tableHeaders}
                data={shownList}
                noDataMessage={t("No_applications_found")}
                setRefetchData={setRefetch}
                
            />
        </div>
    );
}

export default Applications;