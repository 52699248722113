import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../../../components/ModalContainer';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/buttons/Button';
import CloseButton from '../../../components/buttons/CloseButton';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

const CasesTable = ({ tableHeaders, data, noDataMessage, setRefetchData, productId, lessColumns }: any) => {

    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation()
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });


    return (
        <div className={`table_container ${lessColumns && isDesktop && "lessColumns"}`}>
            <div className="table_header">
                {tableHeaders?.map((header: any, index: number) => (
                    <span className={`head ${header.customClass}`} key={index}>{header.label}</span>
                ))}
            </div>
            <div className={`table_data ${(!data || data?.length == 0) && "no_data"} `}>
                {data?.length > 0 ?
                    <>
                        {data?.map((item: any, index: number) => (
                            <div className="item has_logo " key={index} >
                                {/* <div className="column text-center">{item?.id || "-"}</div> */}
                                <div className="column text-center capitalize">{item?.nationality || "-"}</div>
                                <div className="column text-center">{item?.service_period || "-"}</div>
                                <div className="column text-center">{item?.minimum_salary || "-"}</div>
                                <div className="column text-center">{item?.salary_transferred_to || "-"}</div>
                                <div className="column text-center clickable" onClick={() => setOpenModal(item?.id)}>{item?.work_sectors.map((sector: { name: string; }) => sector.name).join(', ') || "-"}</div>
                            </div>
                        ))}
                    </>
                    :
                    <h6>{noDataMessage}</h6>
                }
            </div>
            {openModal && (
                <ModalContainer customClass="x-small">
                    <div className="common-modal">
                        <CloseButton handleClose={() => setOpenModal(false)} />
                        <div className='modal-header'>
                            <h4>Work sectors</h4>
                        </div>
                        <div className='modal-info'>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="sector"
                                name="radio-buttons-group"
                            >
                                {data?.find((item: any) => item.id == openModal).work_sectors?.map((sector: any) => (
                                    <FormControlLabel className='normal-cursor' key={sector.name} value="sector" control={
                                        <Radio
                                            className='normal-cursor'
                                            size="small"
                                            sx={{
                                                color: "black",
                                                '&.Mui-checked': {
                                                    color: "black",
                                                },
                                            }}
                                        />} label={sector.name} />
                                ))}
                            </RadioGroup>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </div>
    );
}

export default CasesTable;