import { useDispatch, useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useCallback } from "react";
import { moveSelectedProduct } from "../../store/redux";
import DraggableProductCard from "./DraggableProductCard";

const CustomerProductsView = () => {

    const { selectedProducts } = useSelector((store: any) => store.findDeal)
    const dispatch = useDispatch()

    const moveCard = useCallback((dragIndex: any, hoverIndex: any) => {
        dispatch(moveSelectedProduct({ fromIndex: dragIndex, toIndex: hoverIndex }));
    }, [dispatch]);

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="search-results customer">
                <div className="products-container">
                    {selectedProducts?.map((item: any, index: number) => (
                        <DraggableProductCard key={index} item={item} index={index} moveCard={moveCard} />
                    )
                    )}
                </div>
            </div>
        </DndProvider>
    );
}

export default CustomerProductsView;