import { FormikProps } from "formik";
import { TOptions } from "../../../../types/types";
import SectionHeader from "../../../../components/SectionHeader";
import FieldWrapper from "../../../../components/formInputs/FieldWrapper";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { customStyles } from "../../../../utils/SelectStyles";
import Button from "../../../../components/buttons/Button";
import { generalGet } from "../../../../API/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

interface IProps {
    formik: FormikProps<any>;
    distributors: TOptions[]
}
const CustomerCarForm = ({ formik, distributors }: IProps) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const { searchData } = useSelector((store: any) => store.findDeal)
    const [vehicleData, setVehicleData] = useState<any>(0)

    const viewStock = () => {
        formik.setFieldValue(`vehicle_id`, "")
        setVehicleData(0)
        setLoading(true)
        generalGet(`/vehicles?chassis=${formik.values.chassis_no}&variant_id=${searchData.variant_id}`)
            .then(data => {
                setLoading(false)
                formik.setFieldValue(`vehicle_id`, data?.data?.data[0]?.id)
                setVehicleData(data?.data?.data[0] == undefined ? null : data?.data?.data[0])
            }).catch((error) => {
                console.log(error)
                setLoading(false)
                toast.error(error?.response?.data?.message || t("somethingWrong"))
            })
    }
    const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
    console.log(vehicleData)

    return (
        <SectionHeader title={t("vehicleInfo")} customStyle={`last ${isMobile && "no-margin"}`}>
            <div className="inputs_group">
                <FieldWrapper
                    customPadding
                    title={t("Distributor")}
                    inputName={`distributor_id`}
                    inputError={formik?.errors?.distributor_id as string}
                    inputTouched={formik?.touched?.distributor_id as boolean}
                    inputPlaceholder={t("selectDistributor")}
                    options={distributors}
                    onChange={(e: any) => formik.setFieldValue(`distributor_id`, e?.value)}
                    selectStyle={customStyles}
                    select
                />
                <FieldWrapper noPadding />
            </div>
            <FieldWrapper title={t("chassisNo")} noPadding>
                <div className="inputs_group special-mobile">
                    <FieldWrapper
                        inputName={`chassis_no`}
                        inputPlaceholder={t("searchByChassisNo")}
                        input
                        inputError={formik?.errors?.chassis_no as string}
                        inputTouched={formik?.touched?.chassis_no as boolean}
                        onBlur={() => setVehicleData(0)}
                    >
                        {vehicleData == null ?
                            <span className="error">{t("noStock")}</span> : ""
                        }
                    </FieldWrapper>
                    <FieldWrapper>
                        <Button
                            loading={loading}
                            disabled={loading || !formik.values.chassis_no}
                            onClick={!loading && formik.values.chassis_no && viewStock}
                            text={t("loadStock")} type={'button'} ></Button>
                    </FieldWrapper>
                </div>
            </FieldWrapper>
            {vehicleData?.variant?.display_name &&
                <FieldWrapper title={"vehicle Details"} noPadding>
                    <div className="inputs_group">
                        <FieldWrapper
                            title={t("carMake")}
                            inputName={`t`}
                            inputPlaceholder={vehicleData?.variant?.vehicle_model?.vehicle_make.name}
                            input
                            disabled
                        />
                        <FieldWrapper
                            title={t("carModel")}
                            inputName={`t`}
                            inputPlaceholder={vehicleData?.variant?.vehicle_model?.name}
                            input
                            disabled
                        />
                        <FieldWrapper
                            title={t("car_variant")}
                            inputName={`t`}
                            inputPlaceholder={vehicleData?.variant?.display_name}
                            input
                            disabled
                        />
                    </div>
                    <div className="inputs_group">
                        <FieldWrapper
                            title={t("interior_color")}
                            inputName={`t`}
                            inputPlaceholder={vehicleData?.trim_cab_type?.display_name}
                            input
                            disabled
                        />
                        <FieldWrapper
                            title={t("exterior_color")}
                            inputName={`t`}
                            inputPlaceholder={vehicleData?.color_wheel_base?.display_name}
                            input
                            disabled
                        />
                    </div>
                </FieldWrapper>
            }
            <FieldWrapper noPadding />
        </SectionHeader>
    );
}

export default CustomerCarForm;