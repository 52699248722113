import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { generalCreate } from "../../../../API/api";
import { scrollToError } from "../../../../utils/HelperFunctions";
import Button from "../../../../components/buttons/Button";
import { toast } from "react-toastify";
import PersonalInfoForm from "./PersonalInfoForm";
import CustomerCarForm from "./CustomerCarForm";
import CustomerProductsView from "./CustomerProductsView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { TOptions } from "../../../../types/types";
import { IProductData } from "../../../products/types/interfaces";
import moment from "moment";

interface IProps {
    setActiveStep: React.Dispatch<React.SetStateAction<number>>
    distributors: TOptions[]
}
const CustomerInfoForm = ({ setActiveStep, distributors }: IProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation()
    const formRef = useRef(null)
    const { searchData, selectedProducts } = useSelector((store: any) => store.findDeal)
    const [products, setProducts] = useState<IProductData[]>([])
    const navigate = useNavigate()

    useEffect(() => {

        const div = document.querySelector('.steps_header');
        div && div.scrollBy(100, 0);

    }, [])

    const validationSchema = Yup.object().shape({
        full_name: Yup.object().shape(
            {
                ar: Yup.string().required(t("required")).matches(/^[\u0600-\u06FF\s]+$/, t("must_ar")).min(3, t("name_short")),
                en: Yup.string().required(t("required")).matches(/^[A-Za-z\s]+$/, t("must_en")).min(3, t("name_short")),
            }),
        email: Yup.string()
            .email(t("email_format"))
            .required(t("required"))
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("email_format")),
        mobile: Yup.string()
            .required(t("required"))
            .matches(/^05[0-9]{8}$/, t("wrong_number")),
        zip_code: Yup.string().required(t("required")).matches(/^[0-9]+$/, t("must_number")).min(3, t("wrong_number")).max(10, t("wrong_number")),
        national_id: Yup.string().required(t("required")).matches(/^[12]\d{9}$/, t("wrong_number")).min(10, t("wrong_number")).max(10, t("wrong_number")),
        job_title: Yup.string().required(t("required")),
        day_of_birth: Yup.string().required(t("required")),
        month_of_birth: Yup.string().required(t("required")),
        year_of_birth: Yup.string().required(t("required")),
        gender: Yup.string().required(t("required")),
        distributor_id: Yup.string().required(t("required")),
        chassis_no: Yup.string().required(t("required")),
        vehicle_id: Yup.string().required(t("required")),
        address: Yup.object().shape(
            {
                city: Yup.string().required(t("required")),
                street: Yup.string().required(t("required")),
                apartment_no: Yup.number().typeError(t('must_be_a_number'))
                    .required(t('required'))
            }),
    });

    const initialValues: any = {
        full_name: { en: "", ar: "" },
        email: "",
        mobile: "",
        national_id: "",
        job_title: "",
        dob: "",
        zip_code: "",
        gender: "",
        distributor_id: "",
        address: { city: "", street: "", apartment_no: "" },
        chassis_no: "",
        vehicle_id: "",
        day_of_birth: "",
        month_of_birth: "",
        year_of_birth: "",
        work_sector_id: searchData?.work_sector_id
    }

    useEffect(() => {
        setProducts((prev: IProductData[]) => {
            const data = selectedProducts?.map((item: IProductData) => (
                {
                    id: item.id,
                    duration: item.duration_to
                }
            ))
            return data
        })
    }, [selectedProducts])

    return (
        <div className="customer-info">
            <div ref={formRef} style={{ scrollMarginTop: "12rem" }}>
                <Formik
                    enableReinitialize
                    validateOnMount
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        values.dob = `${values.year_of_birth}/${values.month_of_birth}/${values.day_of_birth}`
                        if (moment().diff(values.dob, 'years') != searchData?.age) {
                            toast.error("Birth of date mismatches with user age")
                            return
                        }
                        setLoading(true)
                        const {
                            chassis_no,
                            year_of_birth,
                            month_of_birth,
                            day_of_birth,
                            ...rest
                        } = values;
                        const data = {
                            ...rest,
                            mobile: values?.mobile.toString(),
                            salary: searchData.salary,
                            nationality: searchData.nationality,
                            salary_transferred_to_id: searchData.salary_transfer_to,
                            vehicle_price: searchData.price,
                            service_period: searchData.service_period,
                            products
                        }

                        generalCreate({
                            route: "/applications",
                            values: data
                        })
                            .then(res => {
                                setLoading(false)
                                navigate(`/applications/${res.data.data.id}`)
                                toast.success("Application created successfully")
                            })
                            .catch(error => {

                                toast.error(error?.response?.data?.message || t("somethingWrong"))
                                setLoading(false)
                            })
                    }}
                >
                    {(formik) => (
                        <Form >
                            <div className="customer-info-container">
                                <div className="form-wrapper">
                                    <PersonalInfoForm
                                        formik={formik}
                                    />
                                    <CustomerCarForm
                                        formik={formik}
                                        distributors={distributors}
                                    />
                                </div>
                                <div className="products">
                                    <CustomerProductsView />
                                </div>
                            </div>
                            <div className="form_button reverse">
                                <Button
                                    loading={loading}
                                    disabled={loading}
                                    onClick={() => { scrollToError(!formik.isValid, formRef) }}
                                    text={t("submit")} type={'submit'} ></Button>
                                <Button
                                    onClick={() => { setActiveStep(1) }}
                                    text={t("prev")} type={'button'} ></Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    );
}

export default CustomerInfoForm;