import '../styles/app.scss';
import '../styles/app-rtl.scss';
import Layout from '../components/layout/Layout';
import { BrowserRouter as Router, Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import Providers from './Providers';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Login from './pages/auth/Login';
import ProtectedRoutes from '../utils/ProtectedRoutes';
import ProtectedAuth from '../utils/ProtectedAuth';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import ChangePasswordPageComponents from './pages/auth/ChangePassword';
import CreateService from './pages/servicesProviders/CreateService';
import ServiceProviders from './pages/servicesProviders/ServiceProviders';
import ServiceDetails from './pages/servicesProviders/ServiceDetails';
import Products from './pages/products/Products';
import CreateOffer from './pages/products/CreateOffer';
import Distributors from './pages/distributors/Distributors';
import CreateDistributor from './pages/distributors/CreateDistributor';
import DistributorDetails from './pages/distributors/DistributorDetails';
import ProductView from './pages/products/ProductView';
import CreateSales from './pages/sales/CreateSales';
import SalesList from './pages/sales/SalesList';
import Applications from './pages/applications/Applications';
import FindDeal from './pages/applications/FindDeal';
import CreateProduct from './pages/products/CreateProduct';
import ApplicationDetails from './pages/applications/ApplicationDetails';
import SalesDetails from './pages/sales/SalesDetails';

const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.dir = i18n.dir();
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  return (
    <BrowserRouter>
      <Providers>
        <Layout>
          <Routes>
            <Route path='/' element={<ProtectedRoutes />}>
              <Route path="/auth/change-password" element={<ChangePasswordPageComponents />} />
              <Route path="/" element={<Navigate to="/services" />} />
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              <Route path="/services" element={<ServiceProviders />} />
              <Route path="/services/create-service" element={<CreateService />} />
              <Route path="/services/create-service/:id" element={<CreateService />} />
              <Route path="/services/:id" element={<ServiceDetails />} />
              <Route path="/distributors" element={<Distributors />} />
              <Route path="/distributors/create-distributor" element={<CreateDistributor />} />
              <Route path="/distributors/create-distributor/:id" element={<CreateDistributor />} />
              <Route path="/sales/create-sales-executive" element={<CreateSales />} />
              <Route path="/sales/create-sales-executive/:id" element={<CreateSales />} />
              <Route path="/sales" element={<SalesList />} />
              <Route path="/sales/:id" element={<SalesDetails />} />
              <Route path="/distributors/:id" element={<DistributorDetails />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/:id/create-offer" element={<CreateOffer />} />
              <Route path="/products/:id/create-offer/:offerId" element={<CreateOffer />} />
              <Route path="/products/create-product" element={<CreateProduct />} />
              <Route path="/products/create-product/:id" element={<CreateProduct />} />
              <Route path="/products/:id" element={<ProductView />} />
              <Route path="/applications" element={<Applications />} />
              <Route path="/applications/:id" element={<ApplicationDetails />} />
              <Route path="/applications/create/find-deal" element={<FindDeal />} />
              <Route path="/not-found" element={<NotFound />} />
            </Route>
            <Route element={<ProtectedAuth />}>
              <Route path="/auth/login" element={<Login />} />
            </Route>
            <Route path="*" element={<Navigate to="/not-found" replace />} />
          </Routes>
        </Layout>
      </Providers>
    </BrowserRouter>
  );
}

export default App;
