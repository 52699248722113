import { useSelector } from "react-redux";
import ProductCard from "./ProductCard";
import Button from "../../../../components/buttons/Button";
import { useTranslation } from "react-i18next";
import SectionHeader from "../../../../components/SectionHeader";

interface IProps {
    setActiveStep: React.Dispatch<React.SetStateAction<number>>
}
const SearchResults = ({ setActiveStep }: IProps) => {
    
    const { searchResult } = useSelector((store: any) => store.findDeal)
    const { selectedProducts } = useSelector((store: any) => store.findDeal)
    const { t } = useTranslation()

    return (
        <div className="search-results">
            {searchResult.length == 0 ? <div className="no-data">
                <p>{t("sorry")}</p>
                <span>{t("we_could't_find_result")} <span className="link" onClick={() => setActiveStep(0)}>{t("search_criteria")}</span></span>
            </div>
                :
                <SectionHeader title={t("upTo3Products" )}customStyle="last">
                <div className="products-container">
                    {searchResult?.map((item: any, index: number) => (
                        <ProductCard key={index} item={item} />
                    )
                    )}
                    <form >
                        <div className="form_button reverse">
                            <Button
                                disabled={selectedProducts.length == 0}
                                onClick={() => { selectedProducts.length > 0 && setActiveStep(2) }}
                                text={t("next")} type={'button'} ></Button>
                            <Button
                                onClick={() => { setActiveStep(0) }}
                                text={t("prev")} type={'button'} ></Button>
                        </div>
                    </form>
                </div>
                </SectionHeader>
            }

        </div>
    );
}

export default SearchResults;