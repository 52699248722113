export const Nationalities = ["saudi", "non-saudi"]
export const SalaryTo = ["payroll", "non-payroll"]
export const SalaryToCheck = [
	{
		value: "payroll",
		label: "payroll",
	},
	{
		value: "non-payroll",
		label: "non-payroll",
	},
]
export const NationalitiesCheck = [
	{
		value: "saudi",
		label: "saudi",
	},
	{
		value: "non-saudi",
		label: "non-saudi",
	},
]
export const Gender = ["male", "female"]
export const FixedSearchData = {
	vehicle_model_id: "",
	variant_id: "",
	price: "",
	work_sector_id: "",
	nationality: "",
	salary_transfer_to: "",
	age: "",
	salary: "",
	down_payment: "",
	finance_duration: "",
	service_period: "",
	balloon_percentage: "0",
	car_make: "",
}
export const days = Array.from({ length: 31 }, (_, i) => i + 1)
export const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
]
export const currentYear = new Date().getFullYear()
export const years = Array.from({ length: 71 }, (_, i) => currentYear - 70 + i)
