import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedProducts } from "../../store/redux";
import moment from "moment";
import { OfferIcon } from "../../../../config/variables";
import { ISearchProduct } from "../../types/interface";
import { useCallback, useEffect, useState } from "react";

const ProductCard = ({ item }: { item: ISearchProduct }) => {

    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const { selectedProducts } = useSelector((store: any) => store.findDeal)
    const [isExist, setIsExist] = useState(false)
    const [selectedProvider, setSelectedProvider] = useState(false)

    useEffect(() => {

        setSelectedProvider(selectedProducts.find((data: any) =>
            data.service_provider.id == item.service_provider.id
        ))
        setIsExist(selectedProducts.find((data: any) =>
            data.id == item.id && data.duration_to == item.duration_to
        ))

    }, [selectedProducts])

    const hasOffer = useCallback(() => {
        const hasAdminFee = item?.admin_fee?.old_admin_fee_value &&
            item?.admin_fee?.old_admin_fee_value != item?.admin_fee?.admin_fee_value
        const hasProfit = item?.old_profit_value && item?.old_profit_value != item?.profit_value
        return {
            hasAdminFee,
            hasProfit,
            hasOffer: hasAdminFee || hasProfit
        }
    }, [item])

    console.log(item)
    return (
        <div className={`product-card ${((selectedProvider && !isExist) || (selectedProducts.length === 3 && !isExist)) && "normal-cursor"}`} onClick={() => {
            if ((selectedProducts.length < 3 || isExist) && (!selectedProvider || isExist))
                dispatch(setSelectedProducts({
                    ...item,
                    id: item.id,
                    duration_to: item.duration_to
                }))
        }}>
            <div className="check">
                <label>
                    <input
                        type="checkbox"
                        checked={selectedProducts.find(
                            (data: any) => data.duration_to == item?.duration_to && data.id == item.id
                        )}
                    />
                </label>
            </div>
            {hasOffer().hasOffer && <div className="icon">
                {OfferIcon}
            </div>}
            <section className="service-data">
                <div className='logo-container'>
                    {item?.service_provider?.logo_url && <img src={item?.service_provider?.logo_url} alt="" />}
                </div>
                <span>{item?.service_provider?.name[i18n.language]}</span>
            </section>
            <section className="basic-data">
                <div>
                    <p>{`${t("down_payment")} ( ${item?.down_payment_percentage}% )`}</p>
                    <span>{item?.down_payment?.toLocaleString('en-US', { minimumFractionDigits: 2 })} {t("sar")}</span>
                </div>
                <div>
                    <p>{`${t("profit_rate")} ( ${item?.profit_percentage}% )`}</p>
                    {hasOffer().hasProfit && <span className="old">{item?.old_profit_value?.toLocaleString('en-US', { minimumFractionDigits: 2 })} {t("sar")}</span>}
                    <span>{item?.profit_value?.toLocaleString('en-US', { minimumFractionDigits: 2 })} {t("sar")}</span>
                </div>
                <div>
                    <p>{`${t("insurance_fee")} ( ${item?.insurance_fee?.insurance_fee_percentage}% )`}</p>
                    <span>{item?.insurance_fee?.insurance_fee_value?.toLocaleString('en-US', { minimumFractionDigits: 2 })} {t("sar")}</span>
                </div>
                <div>
                    <p>{`${t("admin_fee")} ( ${item?.admin_fee?.admin_fee_percentage}% )`}</p>
                    {hasOffer().hasAdminFee && <span className="old">{item?.admin_fee.old_admin_fee_value?.toLocaleString('en-US', { minimumFractionDigits: 2 })} {t("sar")}</span>}
                    <span>{item?.admin_fee?.admin_fee_value?.toLocaleString('en-US', { minimumFractionDigits: 2 })} {t("sar")}</span>
                </div>
            </section>
            <section className="price-data">
                <div>
                    <p>{t("total_amount")}</p>
                    <span>{item?.total_price?.toLocaleString('en-US', { minimumFractionDigits: 2 })} {t("sar")}</span>
                </div>
                <div>
                    <p>{t("monthly_install")}</p>
                    <span>{item?.monthly_installments?.toLocaleString('en-US', { minimumFractionDigits: 2 })} {t("sar")}</span>
                </div>
                <div>
                    <p>{t("financialDuration")}</p>
                    <span>{item?.duration_to} {item?.duration_to == "1" ? t("year") : t("years")}</span>
                </div>

                {hasOffer().hasOffer && item.offer_end_date && <div className="offer">
                    <span>{t("offerEnds")} {moment(item.offer_end_date).format("MMM Do YYYY")}</span>
                </div>}
            </section>

        </div>
    );
}

export default ProductCard;