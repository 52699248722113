import { useTranslation } from "react-i18next";
import FieldWrapper from "../../../../components/formInputs/FieldWrapper";
import SectionHeader from "../../../../components/SectionHeader";
import { TOptions } from "../../../../types/types";
import { customStyles } from "../../../../utils/SelectStyles";
import { FormikProps } from "formik";
import RadioInput from "../../../../components/formInputs/RadioInput";
import { Nationalities } from "../../../../config/FixedData";
import { useDispatch, useSelector } from "react-redux";
import { FocusEvent, useEffect, useRef } from "react";
import { generalGet } from "../../../../API/api";
import { setCount, setFormData } from "../../store/redux";

interface IProps {
    formik: FormikProps<any>;
    workSectors: TOptions[]
    services: TOptions[]
}
const CustomerDetailsForm = ({ formik, workSectors, services }: IProps) => {

    const { t, i18n } = useTranslation()
    const { searchData } = useSelector((store: any) => store.findDeal)
    const { formData } = useSelector((store: any) => store.findDeal)
    const debounceTimeoutRef = useRef<any>(null);
    const dispatch = useDispatch()


    const durationOptions = [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" }]

    const handleBlur = async (name: string, value: string) => {

        let values =
            { ...formData, [`filters[${name}]`]: value }
        const data = Object.entries(values)
            .map(([key, value]) => `${key}=${value as string}`)
            .join('&');
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }
        debounceTimeoutRef.current = setTimeout(async () => {
            dispatch(setFormData(
                {
                    [`filters[${name}]`]: value
                }
            ))
            generalGet(`/search/products/count?${data}`)
                .then(res => {
                    dispatch(setCount(res.data.data.products_count))
                }).catch((err) => console.log(err))

        }, 250);
    };

    useEffect(() => {
        return () => clearTimeout(debounceTimeoutRef.current)
    }, [])

    return (
        <SectionHeader title={t("customer_details")} customStyle="last ">
            <div className="inputs_group">
                <FieldWrapper title={t("nationality")}>
                    <RadioInput
                        name={`nationality`}
                        onChange={(e: any) => {
                            formik.setFieldValue(`nationality`, e.target.value)
                            handleBlur(`nationality`, e?.target?.value)
                        }}
                        value={formik?.values?.nationality}
                        key={formik?.values?.nationality}
                        data={Nationalities}
                    />
                </FieldWrapper>
                <FieldWrapper noPadding />
            </div>
            <div className="inputs_group">
                <FieldWrapper
                    title={t("age")}
                    inputName={`age`}
                    inputError={formik?.errors?.age as string}
                    inputTouched={formik?.touched?.age as boolean}
                    inputPlaceholder={`${t("ex")} 30`}
                    input
                    type="number"
                    showUnit
                    unit={t("years")}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => handleBlur(e?.target?.name, e?.target?.value)}
                />
                <FieldWrapper
                    title={t("salary")}
                    inputName={`salary`}
                    inputError={formik?.errors?.salary as string}
                    inputTouched={formik?.touched?.salary as boolean}
                    inputPlaceholder={`${t("ex")} 30000`}
                    input
                    type="number"
                    showUnit
                    unit={t("sar")}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => handleBlur(e?.target?.name, e?.target?.value)}
                />
                <FieldWrapper
                    title={t("salaryTransfer")}
                    inputName={`salary_transfer_to`}
                    inputPlaceholder={t("selectServiceProvider")}
                    inputError={formik?.errors?.salary_transfer_to as string}
                    inputTouched={formik?.touched?.salary_transfer_to as boolean}
                    options={services}
                    onChange={(e) => {
                        formik.setFieldValue("salary_transfer_to", e?.value)
                        handleBlur("salary_transfer_to", e?.value as string)
                    }}
                    selectStyle={customStyles}
                    select
                    defaultValue={searchData?.salary_transfer_to && services?.find((option: TOptions) => option.value == searchData?.salary_transfer_to)}
                    key={searchData?.salary_transfer_to && services?.find((option: TOptions) => option.value == searchData?.salary_transfer_to)}
                />
            </div>
            <div className="inputs_group">
                <FieldWrapper
                    customPadding
                    title={t("workSector")}
                    inputName={`work_sector_id`}
                    inputError={formik?.errors?.work_sector_id as string}
                    inputTouched={formik?.touched?.work_sector_id as boolean}
                    inputPlaceholder={t("selectWorkSectors")}
                    options={workSectors}
                    onChange={(e: any) => {
                        formik.setFieldValue(`work_sector_id`, e?.value)
                        handleBlur("work_sector_id", e?.value as string)
                    }}
                    selectStyle={customStyles}
                    select
                    defaultValue={searchData?.work_sector_id && workSectors?.find((option: TOptions) => option.value == searchData?.work_sector_id)}
                    key={searchData?.work_sector_id && workSectors?.find((option: TOptions) => option.value == searchData?.work_sector_id)}
                />
                <FieldWrapper
                    customPadding
                    title={t("financialDuration")}
                    inputName={"finance_duration"}
                    inputError={formik.errors.finance_duration as string}
                    inputTouched={formik.touched.finance_duration as boolean}
                    inputPlaceholder={t("selectDuration")}
                    options={durationOptions}
                    onChange={(e) => {
                        formik.setFieldValue("finance_duration", e?.value);
                        handleBlur("finance_duration", e?.value as string)
                    }}
                    defaultValue={searchData.finance_duration && durationOptions?.find((option: TOptions) => option.value == searchData.finance_duration)}
                    key={searchData.finance_duration && durationOptions?.find((option: TOptions) => option.value == searchData.finance_duration)?.value}
                    selectStyle={customStyles}
                    select
                />
                <FieldWrapper
                    title={t("servicePeriod")}
                    inputName={`service_period`}
                    inputError={formik?.errors?.service_period as string}
                    inputTouched={formik?.touched?.service_period as boolean}
                    inputPlaceholder={`${t("ex")} 12`}
                    input
                    type="number"
                    showUnit
                    unit={t("month")}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => handleBlur(e?.target?.name, e?.target?.value)}
                />
            </div>
            <div className="inputs_group">
                <FieldWrapper
                    title={t("downPayment")}
                    inputName={`down_payment`}
                    inputError={formik?.errors?.down_payment as string}
                    inputTouched={formik?.touched?.down_payment as boolean}
                    inputPlaceholder={`${t("ex")} 12`}
                    input
                    type="number"
                    showUnit
                    unit={"%"}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => handleBlur(e?.target?.name, e?.target?.value)}
                />
                <FieldWrapper
                    title={t("balloonPercentage")}
                    inputName={`balloon_percentage`}
                    inputError={formik?.errors?.balloon_percentage as string}
                    inputTouched={formik?.touched?.balloon_percentage as boolean}
                    inputPlaceholder={`${t("ex")} 12`}
                    input
                    type="number"
                    showUnit
                    unit={"%"}
                />
                <FieldWrapper noPadding />
            </div>
        </SectionHeader>
    );
}

export default CustomerDetailsForm;